import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import Header from './components/Header';
import Home from './components/Home';
import Work from './components/Work';
import About from './components/About';
import Skills from './components/Skills';
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import './styles/App.scss';

function App() {
  return (
      <div className="App">
        <Router>
          <Route render={({ location }) => (
            <>
            <Header />
            <TransitionGroup>
              <CSSTransition timeout={1000} classNames='fade' key={location.key}>
                <Switch location={location}>
                  <Route exact path='/' component={Home} />
                  <Route path='/projects' component={Work} />
                  <Route path='/skills' component={Skills}/>
                  <Route path='/about' component={About} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
            </>
          )}/>
        </Router>
      </div>
  );
}

export default App;
