import React from 'react';
import '../styles/Work.scss'
import mud from '../images/MUD.png'
import acs from '../images/ACS.png'
import urbanBottle from '../images/urbanBottle.png';
import reactWars from '../images/react-wars.png';
import formulaOne from '../images/formula1.png';
import plantwise from '../images/plantwise.png';

const Work = () => {
  return(
    <div className='container'>
      <div className='card'>
        <h3>Recent Work</h3>
        <p>This is my most recent work. Other projects can be found on my <a href='github.com/tayorbcool'>GitHub</a>.</p>

        <div className='work'>
          <a href='https://adaptivdev.net/' target=''><img className='work-img' src={acs} alt='ACS landing page' /></a>
          <h5>Angel City Sports Events Management Web App</h5>
          <p>As a fullstack developer on this project I assisted with both the front-end implementation as well as the Prisma and Apollo backend using GraphQL for our API. In addition to learning GraphQL, Prisma, and Apollo for this project, I assisted in the design and creation of a proprietary style library. This project was completed over the course of 7 weeks.</p>
        </div>

        <div className='work'>
          <a href='https://cs28-buildweek-robert2-fe.web.app/register' target=''><img className='work-img' src={mud} alt='View of the dungeon map after login' /></a>
          <h5>Multi-user Online Dungeon</h5>
          <p>As the head of the front-end team I lead the design and implementation of a solution for displaying information from our Django REST API to create an online, multiplayer dungeon crawler. This project was completed in just 4 days. The map is randomly generated when the game is created, and all users are able to log in at the same time and play on the same map.</p>
        </div>

        <div className='work'>
          <a href='https://plantwise.netlify.com/' target=''><img className='work-img' src={plantwise} alt='PlantWise react app landing page' /></a>
          <h5>PlantWise</h5>
          <p>As the lone back-end architect, I helped create a react and iOS app to help users remember when to water their plants. This project was completed over the course of 4 days.</p>
        </div>
      </div>
    </div>
  )
}  
  export default Work;