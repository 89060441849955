import React from 'react';
import emailjs from 'emailjs-com';
import { MdEmail } from 'react-icons/md';
import '../styles/Contact.scss'

const Contact = () => {
  const sendEmail = (e) => {
    e.preventDefault()

    emailjs.sendForm('gmail', 'portfolio_contact', e.target, 'user_G9wVZN24Yp75m8teQbTmT')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }

  return(
    <>
      <h3>Contact Taylor</h3>
      <p>Have a project you'd like to collaborate on?</p>
      <div className='contact-flex'>
        <div className='contact-flex-col'>
          <MdEmail />
          <p>Email me!</p>
        </div>
        <form className="contact-form" onSubmit={sendEmail}>
          <input type="hidden" name="contact_number" />
          <label>Name</label>
          <input type="text" name="user_name" className='name' />
          <label>Email</label>
          <input type="email" name="user_email" className='email' />
          <label>Message</label>
          <textarea name="message" className='message' />
          <input type="submit" value="Send" className='button' />
        </form>
      </div>
    </>
  )
}

export default Contact