import React from 'react';
import '../styles/About.scss'

const Skills = () =>{
    return (
        <div className='container'>
            <div className='card'>
              <h3>Skills:</h3>
              <div className='flex'>
                <div className='flex-col'>
                  <p>HTML5</p>
                  <p>JavaScript ES6</p>
                  <p>React.js</p>
                  <p>React Redux</p>
                  <p>Node.js</p>
                </div>
                <div className='flex-col'>
                  <p>Express</p>
                  <p>Python 3</p>
                  <p>Django</p>
                  <p>Jest</p>
                  <p>React Testing library</p>
                </div>
                <div className='flex-col'>
                  <p>Sqlite</p>
                  <p>PostgreSQL</p>
                  <p>Prisma</p>
                  <p>Apollo</p>
                  <p>GraphQL</p>
                </div>
                <div className='flex-col'>
                  <p>Material UI</p>
                  <p>Bootstrap</p>
                  <p>Chakra UI</p>
                  <p>CSS Preprocessors</p>
                  <p>CSS3</p>
                </div>
              </div>
            </div>
        </div>
    )
}

export default Skills;